import links from "../Utils/External-URLs";
import "../Styles/GitHub.css";

const { default: React } = require("react");
const { Component } = require("react");

class Github extends Component {
  state = {};

  render() {
    let homePage = (
      <div className="GitHub">
        This is where I list/show off github projects
        <br />
        <a href={links.Github} target="_blank" rel="noreferrer noopener">
          A link to the Github Account
        </a>
      </div>
    );
    return homePage;
  }
}
export default Github;
