import logo from "../Assets/logo.svg";
import "../Styles/App.css";
import React from "react";
import Header from "../Components/Header";
import { Route, Routes } from "react-router-dom";
import Github from "./GitHub";

function App() {
  return (
    <div className="App">
      <div> This is App.jsx</div>
      <Routes>
        <Route path="#gh" element={<Github />} />
      </Routes>
    </div>
  );
}

export default App;
