import Header from "../Components/Header";

const { default: React } = require("react");
const { Component } = require("react");

class HomePage extends Component {
  state = {};

  render() {
    let homePage = <div className="Home-Page">This is the home page</div>;
    return homePage;
  }
}
export default HomePage;
