import "../Styles/Header.css";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
const { default: React } = require("react");
const { Component } = require("react");

class Header extends Component {
  state = {};

  render() {
    let homePage = (
      <>
        <Navbar bg="dark" variant="dark">
          <Container>
            <Navbar.Brand href="/">Portfolio</Navbar.Brand>
            <Nav className="me-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/gh">Github</Nav.Link>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
    return homePage;
  }
}
export default Header;
